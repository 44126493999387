
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  detectRuntime,
} = require('./runtime/index-browser')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.3.1
 * Query Engine version: 61e140623197a131c2a6189271ffee05a7aa9a59
 */
Prisma.prismaVersion = {
  client: "5.3.1",
  engine: "61e140623197a131c2a6189271ffee05a7aa9a59"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.NotFoundError = () => {
  throw new Error(`NotFoundError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  throw new Error(`Extensions.getExtensionContext is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}
Prisma.defineExtension = () => {
  throw new Error(`Extensions.defineExtension is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://github.com/prisma/prisma/issues`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.RoleScalarFieldEnum = {
  id: 'id',
  role: 'role',
  description: 'description'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  oldId: 'oldId',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  insertedTimestamp: 'insertedTimestamp',
  updatedTimestamp: 'updatedTimestamp',
  stripeId: 'stripeId',
  rechargeCustomerId: 'rechargeCustomerId',
  rechargeHash: 'rechargeHash',
  shopifyCustomerId: 'shopifyCustomerId',
  phoneNumber: 'phoneNumber'
};

exports.Prisma.UserToRoleScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  roleId: 'roleId',
  insertedUserId: 'insertedUserId',
  insertedTimestamp: 'insertedTimestamp',
  updatedUserId: 'updatedUserId',
  updatedTimestamp: 'updatedTimestamp'
};

exports.Prisma.UserPasswordScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  insertedTimestamp: 'insertedTimestamp',
  updatedTimestamp: 'updatedTimestamp',
  hash: 'hash',
  salt: 'salt'
};

exports.Prisma.PasswordResetLinkScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  insertedTimestamp: 'insertedTimestamp',
  expiresAt: 'expiresAt',
  usedAt: 'usedAt',
  hash: 'hash'
};

exports.Prisma.AuthLoginCodeScalarFieldEnum = {
  id: 'id',
  insertedTimestamp: 'insertedTimestamp',
  expiresAt: 'expiresAt',
  usedAt: 'usedAt',
  redirectTo: 'redirectTo',
  userId: 'userId'
};

exports.Prisma.RefreshTokenScalarFieldEnum = {
  id: 'id',
  token: 'token',
  userId: 'userId',
  issuedAt: 'issuedAt',
  expiresAt: 'expiresAt',
  invalidatedAt: 'invalidatedAt'
};

exports.Prisma.KatkinImageScalarFieldEnum = {
  id: 'id',
  mimetype: 'mimetype',
  path: 'path',
  length: 'length',
  status: 'status',
  title: 'title',
  caption: 'caption',
  insertedTimestamp: 'insertedTimestamp',
  updatedTimestamp: 'updatedTimestamp',
  views: 'views',
  type: 'type'
};

exports.Prisma.UserImageScalarFieldEnum = {
  userId: 'userId',
  imageId: 'imageId'
};

exports.Prisma.CatImageScalarFieldEnum = {
  catId: 'catId',
  imageId: 'imageId'
};

exports.Prisma.CatScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  name: 'name',
  insertedTimestamp: 'insertedTimestamp',
  updatedTimestamp: 'updatedTimestamp'
};

exports.Prisma.OrderFulfilmentScalarFieldEnum = {
  id: 'id',
  boxId: 'boxId',
  rechargeSubscriptionId: 'rechargeSubscriptionId',
  rechargeCustomerId: 'rechargeCustomerId',
  rechargeOrderId: 'rechargeOrderId',
  rechargeChargeId: 'rechargeChargeId',
  subscriptionBoxNumber: 'subscriptionBoxNumber',
  userId: 'userId',
  catName: 'catName',
  catId: 'catId',
  createdAt: 'createdAt',
  createdByUserId: 'createdByUserId',
  updatedByUserId: 'updatedByUserId',
  updatedAt: 'updatedAt',
  email: 'email',
  shippingFirstName: 'shippingFirstName',
  shippingLastName: 'shippingLastName',
  shippingAddress1: 'shippingAddress1',
  shippingAddress2: 'shippingAddress2',
  shippingCompany: 'shippingCompany',
  shippingCity: 'shippingCity',
  shippingProvince: 'shippingProvince',
  shippingPostcode: 'shippingPostcode',
  shippingCountry: 'shippingCountry',
  shippingPhone: 'shippingPhone',
  dpdAccount: 'dpdAccount',
  dpdConsignment: 'dpdConsignment',
  dpdShipment: 'dpdShipment',
  shippingLabel: 'shippingLabel',
  type: 'type',
  source: 'source',
  replacementReason: 'replacementReason',
  deliveryDate: 'deliveryDate',
  status: 'status',
  submittedAt: 'submittedAt',
  cancelledAt: 'cancelledAt',
  category: 'category',
  shopifyCustomerId: 'shopifyCustomerId',
  shopifyOrderId: 'shopifyOrderId',
  fulfilledByOakland: 'fulfilledByOakland',
  duplicatedFromId: 'duplicatedFromId',
  experimentCode: 'experimentCode',
  indexWithinOrder: 'indexWithinOrder',
  populatedAt: 'populatedAt'
};

exports.Prisma.OrderFulfilmentItemScalarFieldEnum = {
  id: 'id',
  insertedAt: 'insertedAt',
  orderId: 'orderId',
  quantity: 'quantity',
  skuId: 'skuId',
  createdAt: 'createdAt',
  createdByUserId: 'createdByUserId',
  updatedByUserId: 'updatedByUserId',
  updatedAt: 'updatedAt',
  rechargeItemId: 'rechargeItemId',
  source: 'source'
};

exports.Prisma.OrderFulfilmentEditLogScalarFieldEnum = {
  id: 'id',
  insertedAt: 'insertedAt',
  info: 'info',
  orderFulfilmentId: 'orderFulfilmentId',
  byUserId: 'byUserId'
};

exports.Prisma.BoxSkuScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  insertedAt: 'insertedAt',
  maxWeight: 'maxWeight',
  weight: 'weight',
  oaklandSku: 'oaklandSku'
};

exports.Prisma.NonOaklandFulfilledCustomerScalarFieldEnum = {
  shopifyCustomerId: 'shopifyCustomerId'
};

exports.Prisma.ProductScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  refrigerationRequired: 'refrigerationRequired',
  category: 'category',
  allergens: 'allergens',
  deprecated: 'deprecated'
};

exports.Prisma.SkuScalarFieldEnum = {
  id: 'id',
  shopifyVariantIds: 'shopifyVariantIds',
  oaklandSku: 'oaklandSku',
  weight: 'weight',
  name: 'name',
  size: 'size',
  productId: 'productId'
};

exports.Prisma.BoxRuleScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  types: 'types',
  categories: 'categories',
  pouchSizes: 'pouchSizes',
  minPouchCount: 'minPouchCount',
  maxPouchCount: 'maxPouchCount',
  minBoxNumber: 'minBoxNumber',
  maxBoxNumber: 'maxBoxNumber',
  applyToAllBoxIndexes: 'applyToAllBoxIndexes',
  requiredSkuId: 'requiredSkuId',
  starts: 'starts',
  ends: 'ends'
};

exports.Prisma.BoxRuleItemScalarFieldEnum = {
  id: 'id',
  ruleId: 'ruleId',
  skuId: 'skuId',
  quantity: 'quantity'
};

exports.Prisma.LockScalarFieldEnum = {
  id: 'id',
  lockedUntil: 'lockedUntil'
};

exports.Prisma.GdprDeletionScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  deleterId: 'deleterId',
  created: 'created',
  scheduled: 'scheduled',
  deleted: 'deleted'
};

exports.Prisma.GlobalStatisticsScalarFieldEnum = {
  id: 'id',
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  e: 'e',
  f: 'f',
  totalCatParents: 'totalCatParents',
  calculated: 'calculated',
  wholeTableScan: 'wholeTableScan'
};

exports.Prisma.DiscountCategoryScalarFieldEnum = {
  name: 'name',
  createdById: 'createdById',
  createdAt: 'createdAt'
};

exports.Prisma.DiscountSubcategoryScalarFieldEnum = {
  id: 'id',
  name: 'name',
  categoryId: 'categoryId',
  description: 'description',
  createdById: 'createdById',
  createdAt: 'createdAt'
};

exports.Prisma.DiscountGroupScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  createdById: 'createdById',
  createdAt: 'createdAt'
};

exports.Prisma.DiscountCodeScalarFieldEnum = {
  id: 'id',
  code: 'code',
  type: 'type',
  amount: 'amount',
  maxCustomersApplicable: 'maxCustomersApplicable',
  maxChargesPerCustomer: 'maxChargesPerCustomer',
  starts: 'starts',
  ends: 'ends',
  canBeAppliedUntil: 'canBeAppliedUntil',
  applicationDuration: 'applicationDuration',
  canBeAppliedBy: 'canBeAppliedBy',
  applicableProducts: 'applicableProducts',
  applicableChargeTypes: 'applicableChargeTypes',
  note: 'note',
  subcategoryId: 'subcategoryId',
  groupId: 'groupId',
  creditReason: 'creditReason',
  createdAt: 'createdAt',
  createdById: 'createdById',
  lastUpdatedById: 'lastUpdatedById',
  disabledAt: 'disabledAt',
  disabledById: 'disabledById',
  specialCondition: 'specialCondition'
};

exports.Prisma.DiscountAuditLogScalarFieldEnum = {
  id: 'id',
  discountId: 'discountId',
  userId: 'userId',
  timestamp: 'timestamp',
  info: 'info'
};

exports.Prisma.DiscountApplicationScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  discountId: 'discountId',
  expiresAt: 'expiresAt',
  appliedAt: 'appliedAt',
  appliedByUserId: 'appliedByUserId',
  chargeCount: 'chargeCount',
  fullyUsedUp: 'fullyUsedUp',
  disabledAt: 'disabledAt',
  disabledById: 'disabledById'
};

exports.Prisma.DiscountApplicationToDiscountChargeScalarFieldEnum = {
  discountApplicationId: 'discountApplicationId',
  discountChargeId: 'discountChargeId',
  order: 'order',
  discountAmount: 'discountAmount'
};

exports.Prisma.DiscountChargeScalarFieldEnum = {
  id: 'id',
  chargeId: 'chargeId',
  createdAt: 'createdAt',
  associatedAt: 'associatedAt',
  rechargeDiscountCode: 'rechargeDiscountCode',
  rechargeDiscountId: 'rechargeDiscountId',
  rechargeDiscountAmount: 'rechargeDiscountAmount'
};

exports.Prisma.DiscountedLineItemChargeScalarFieldEnum = {
  discountChargeId: 'discountChargeId',
  purchaseItemId: 'purchaseItemId',
  originalUnitPrice: 'originalUnitPrice',
  quantity: 'quantity',
  originalTotalPrice: 'originalTotalPrice',
  finalUnitPrice: 'finalUnitPrice',
  finalTotalPrice: 'finalTotalPrice',
  unitPriceReducedByAmount: 'unitPriceReducedByAmount',
  totalPriceReducedByAmount: 'totalPriceReducedByAmount'
};

exports.Prisma.DiscountedLineItemApplicationScalarFieldEnum = {
  discountChargeId: 'discountChargeId',
  purchaseItemId: 'purchaseItemId',
  discountApplicationId: 'discountApplicationId',
  originalUnitPrice: 'originalUnitPrice',
  quantity: 'quantity',
  originalTotalPrice: 'originalTotalPrice',
  finalUnitPrice: 'finalUnitPrice',
  finalTotalPrice: 'finalTotalPrice',
  unitPriceReducedByAmount: 'unitPriceReducedByAmount',
  totalPriceReducedByAmount: 'totalPriceReducedByAmount'
};

exports.Prisma.UserStatisticsScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  rechargeCustomerId: 'rechargeCustomerId',
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  e: 'e',
  f: 'f',
  calculated: 'calculated'
};

exports.Prisma.ChargeScalarFieldEnum = {
  id: 'id',
  rechargeId: 'rechargeId'
};

exports.Prisma.CreditChargeScalarFieldEnum = {
  id: 'id',
  chargeId: 'chargeId',
  amount: 'amount',
  creditApplicationId: 'creditApplicationId'
};

exports.Prisma.CreditApplicationScalarFieldEnum = {
  id: 'id',
  amount: 'amount',
  fullyUsedUp: 'fullyUsedUp',
  category: 'category',
  subcategory: 'subcategory',
  userId: 'userId',
  appliedByUserId: 'appliedByUserId',
  appliedAt: 'appliedAt',
  disabledAt: 'disabledAt',
  disabledById: 'disabledById',
  note: 'note'
};

exports.Prisma.DisallowedDeliveryDateScalarFieldEnum = {
  id: 'id',
  deliveryDate: 'deliveryDate',
  createdAt: 'createdAt',
  createdById: 'createdById',
  orderType: 'orderType',
  productType: 'productType',
  reason: 'reason'
};

exports.Prisma.BreederScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  gccfPrefix: 'gccfPrefix',
  xeroContactId: 'xeroContactId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CheckoutBasketScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  createdAt: 'createdAt',
  body: 'body',
  response: 'response',
  updatedAt: 'updatedAt',
  status: 'status'
};

exports.Prisma.RechargeOrderScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  customerEmail: 'customerEmail',
  rechargeChargeId: 'rechargeChargeId',
  rechargeCustomerId: 'rechargeCustomerId',
  billingAddress: 'billingAddress',
  shippingAddress: 'shippingAddress',
  insertedAt: 'insertedAt',
  processedAt: 'processedAt',
  scheduledAt: 'scheduledAt',
  totalPrice: 'totalPrice',
  totalTax: 'totalTax',
  stripeTransactionId: 'stripeTransactionId',
  raw: 'raw',
  deliveryDate: 'deliveryDate'
};

exports.Prisma.RechargeLineItemScalarFieldEnum = {
  purchaseItemId: 'purchaseItemId',
  rechargeOrderId: 'rechargeOrderId',
  productTitle: 'productTitle',
  shopifyProductId: 'shopifyProductId',
  variantTitle: 'variantTitle',
  variantId: 'variantId',
  quantity: 'quantity',
  unitPrice: 'unitPrice',
  catName: 'catName',
  catId: 'catId',
  parentSubscriptionId: 'parentSubscriptionId',
  katkinLegacyCustomerId: 'katkinLegacyCustomerId',
  insertedAt: 'insertedAt',
  raw: 'raw',
  scoopPlanType: 'scoopPlanType',
  deliveryDateOverride: 'deliveryDateOverride'
};

exports.Prisma.SurveyResponseScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  surveyId: 'surveyId',
  catId: 'catId',
  fieldName: 'fieldName',
  question: 'question',
  response: 'response',
  createdAt: 'createdAt'
};

exports.Prisma.ExperimentScalarFieldEnum = {
  id: 'id',
  code: 'code',
  name: 'name',
  description: 'description',
  active: 'active',
  startDate: 'startDate',
  endDate: 'endDate'
};

exports.Prisma.ExperimentToUserScalarFieldEnum = {
  userId: 'userId',
  experimentId: 'experimentId',
  group: 'group'
};

exports.Prisma.ShippingAddressScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  firstName: 'firstName',
  lastName: 'lastName',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  province: 'province',
  countryCode: 'countryCode',
  company: 'company',
  phone: 'phone',
  postcode: 'postcode',
  deliveryInstructions: 'deliveryInstructions',
  what3Words: 'what3Words',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  syncedAt: 'syncedAt',
  syncedFor: 'syncedFor',
  rechargeAddressId: 'rechargeAddressId'
};

exports.Prisma.CustomerEventLogScalarFieldEnum = {
  id: 'id',
  targetUserId: 'targetUserId',
  oldData: 'oldData',
  newData: 'newData',
  type: 'type',
  source: 'source',
  causeUserId: 'causeUserId',
  metadata: 'metadata',
  createdAt: 'createdAt',
  targetDataId: 'targetDataId'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.RoleOrderByRelevanceFieldEnum = {
  description: 'description'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.UserOrderByRelevanceFieldEnum = {
  id: 'id',
  oldId: 'oldId',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  stripeId: 'stripeId',
  rechargeHash: 'rechargeHash',
  phoneNumber: 'phoneNumber'
};

exports.Prisma.UserToRoleOrderByRelevanceFieldEnum = {
  userId: 'userId',
  insertedUserId: 'insertedUserId',
  updatedUserId: 'updatedUserId'
};

exports.Prisma.UserPasswordOrderByRelevanceFieldEnum = {
  userId: 'userId',
  hash: 'hash',
  salt: 'salt'
};

exports.Prisma.PasswordResetLinkOrderByRelevanceFieldEnum = {
  userId: 'userId',
  hash: 'hash'
};

exports.Prisma.AuthLoginCodeOrderByRelevanceFieldEnum = {
  id: 'id',
  redirectTo: 'redirectTo',
  userId: 'userId'
};

exports.Prisma.RefreshTokenOrderByRelevanceFieldEnum = {
  id: 'id',
  token: 'token',
  userId: 'userId'
};

exports.Prisma.KatkinImageOrderByRelevanceFieldEnum = {
  id: 'id',
  mimetype: 'mimetype',
  path: 'path',
  title: 'title',
  caption: 'caption'
};

exports.Prisma.UserImageOrderByRelevanceFieldEnum = {
  userId: 'userId',
  imageId: 'imageId'
};

exports.Prisma.CatImageOrderByRelevanceFieldEnum = {
  catId: 'catId',
  imageId: 'imageId'
};

exports.Prisma.CatOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId',
  name: 'name'
};

exports.Prisma.OrderFulfilmentOrderByRelevanceFieldEnum = {
  userId: 'userId',
  catName: 'catName',
  catId: 'catId',
  createdByUserId: 'createdByUserId',
  updatedByUserId: 'updatedByUserId',
  email: 'email',
  shippingFirstName: 'shippingFirstName',
  shippingLastName: 'shippingLastName',
  shippingAddress1: 'shippingAddress1',
  shippingAddress2: 'shippingAddress2',
  shippingCompany: 'shippingCompany',
  shippingCity: 'shippingCity',
  shippingProvince: 'shippingProvince',
  shippingPostcode: 'shippingPostcode',
  shippingCountry: 'shippingCountry',
  shippingPhone: 'shippingPhone',
  shippingLabel: 'shippingLabel',
  replacementReason: 'replacementReason',
  experimentCode: 'experimentCode'
};

exports.Prisma.OrderFulfilmentItemOrderByRelevanceFieldEnum = {
  createdByUserId: 'createdByUserId',
  updatedByUserId: 'updatedByUserId'
};

exports.Prisma.OrderFulfilmentEditLogOrderByRelevanceFieldEnum = {
  info: 'info',
  byUserId: 'byUserId'
};

exports.Prisma.BoxSkuOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description',
  oaklandSku: 'oaklandSku'
};

exports.Prisma.ProductOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description'
};

exports.Prisma.SkuOrderByRelevanceFieldEnum = {
  oaklandSku: 'oaklandSku',
  name: 'name'
};

exports.Prisma.BoxRuleOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description'
};

exports.Prisma.LockOrderByRelevanceFieldEnum = {
  id: 'id'
};

exports.Prisma.GdprDeletionOrderByRelevanceFieldEnum = {
  userId: 'userId',
  deleterId: 'deleterId'
};

exports.Prisma.DiscountCategoryOrderByRelevanceFieldEnum = {
  name: 'name',
  createdById: 'createdById'
};

exports.Prisma.DiscountSubcategoryOrderByRelevanceFieldEnum = {
  name: 'name',
  categoryId: 'categoryId',
  description: 'description',
  createdById: 'createdById'
};

exports.Prisma.DiscountGroupOrderByRelevanceFieldEnum = {
  name: 'name',
  description: 'description',
  createdById: 'createdById'
};

exports.Prisma.DiscountCodeOrderByRelevanceFieldEnum = {
  code: 'code',
  note: 'note',
  creditReason: 'creditReason',
  createdById: 'createdById',
  lastUpdatedById: 'lastUpdatedById',
  disabledById: 'disabledById'
};

exports.Prisma.DiscountAuditLogOrderByRelevanceFieldEnum = {
  userId: 'userId',
  info: 'info'
};

exports.Prisma.DiscountApplicationOrderByRelevanceFieldEnum = {
  userId: 'userId',
  appliedByUserId: 'appliedByUserId',
  disabledById: 'disabledById'
};

exports.Prisma.DiscountChargeOrderByRelevanceFieldEnum = {
  rechargeDiscountCode: 'rechargeDiscountCode'
};

exports.Prisma.UserStatisticsOrderByRelevanceFieldEnum = {
  userId: 'userId'
};

exports.Prisma.CreditApplicationOrderByRelevanceFieldEnum = {
  category: 'category',
  subcategory: 'subcategory',
  userId: 'userId',
  appliedByUserId: 'appliedByUserId',
  disabledById: 'disabledById',
  note: 'note'
};

exports.Prisma.DisallowedDeliveryDateOrderByRelevanceFieldEnum = {
  createdById: 'createdById'
};

exports.Prisma.BreederOrderByRelevanceFieldEnum = {
  userId: 'userId',
  gccfPrefix: 'gccfPrefix',
  xeroContactId: 'xeroContactId'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};

exports.Prisma.CheckoutBasketOrderByRelevanceFieldEnum = {
  id: 'id',
  userId: 'userId'
};

exports.Prisma.RechargeOrderOrderByRelevanceFieldEnum = {
  userId: 'userId',
  customerEmail: 'customerEmail',
  stripeTransactionId: 'stripeTransactionId'
};

exports.Prisma.RechargeLineItemOrderByRelevanceFieldEnum = {
  productTitle: 'productTitle',
  variantTitle: 'variantTitle',
  catName: 'catName',
  catId: 'catId',
  katkinLegacyCustomerId: 'katkinLegacyCustomerId',
  scoopPlanType: 'scoopPlanType'
};

exports.Prisma.SurveyResponseOrderByRelevanceFieldEnum = {
  userId: 'userId',
  surveyId: 'surveyId',
  catId: 'catId',
  fieldName: 'fieldName',
  question: 'question'
};

exports.Prisma.ExperimentOrderByRelevanceFieldEnum = {
  code: 'code',
  name: 'name',
  description: 'description'
};

exports.Prisma.ExperimentToUserOrderByRelevanceFieldEnum = {
  userId: 'userId',
  group: 'group'
};

exports.Prisma.ShippingAddressOrderByRelevanceFieldEnum = {
  userId: 'userId',
  firstName: 'firstName',
  lastName: 'lastName',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  province: 'province',
  countryCode: 'countryCode',
  company: 'company',
  phone: 'phone',
  postcode: 'postcode',
  deliveryInstructions: 'deliveryInstructions',
  what3Words: 'what3Words'
};

exports.Prisma.CustomerEventLogOrderByRelevanceFieldEnum = {
  targetUserId: 'targetUserId',
  causeUserId: 'causeUserId',
  targetDataId: 'targetDataId'
};
exports.RoleType = exports.$Enums.RoleType = {
  CX_AGENT: 'CX_AGENT',
  CX_LEAD: 'CX_LEAD',
  CUTOFF_AGENT: 'CUTOFF_AGENT',
  DEV_SUPERUSER: 'DEV_SUPERUSER',
  DISCOUNT_MANAGER: 'DISCOUNT_MANAGER',
  DISCOUNT_VIEWER: 'DISCOUNT_VIEWER',
  SECURITY_MANAGER: 'SECURITY_MANAGER',
  CUSTOMER: 'CUSTOMER',
  DELIVERY_DATES_MANAGER: 'DELIVERY_DATES_MANAGER',
  BREEDER: 'BREEDER'
};

exports.Status = exports.$Enums.Status = {
  DELETED: 'DELETED',
  PASSIVE: 'PASSIVE',
  ACTIVE: 'ACTIVE'
};

exports.ImageType = exports.$Enums.ImageType = {
  CAT: 'CAT',
  USER: 'USER'
};

exports.OrderType = exports.$Enums.OrderType = {
  RECURRING: 'RECURRING',
  STARTER: 'STARTER',
  REPLACEMENT: 'REPLACEMENT',
  CUSTOM: 'CUSTOM',
  ONEOFF: 'ONEOFF',
  ALL: 'ALL'
};

exports.OrderSource = exports.$Enums.OrderSource = {
  PURCHASE: 'PURCHASE',
  REPLACEMENT: 'REPLACEMENT',
  CUSTOM: 'CUSTOM'
};

exports.OrderFulfilmentStatus = exports.$Enums.OrderFulfilmentStatus = {
  DRAFT: 'DRAFT',
  SUBMISSION_FAILED: 'SUBMISSION_FAILED',
  SUBMITTED: 'SUBMITTED',
  CANCELLED: 'CANCELLED'
};

exports.OrderCategory = exports.$Enums.OrderCategory = {
  FRESH_MEAL_PLAN: 'FRESH_MEAL_PLAN',
  LITTER: 'LITTER',
  CUSTOM: 'CUSTOM'
};

exports.OrderItemSource = exports.$Enums.OrderItemSource = {
  ORDER: 'ORDER',
  MANUALLY_ADDED: 'MANUALLY_ADDED',
  BOX_RULE: 'BOX_RULE'
};

exports.ProductTag = exports.$Enums.ProductTag = {
  FRESH_MEAL: 'FRESH_MEAL',
  LITTER: 'LITTER',
  TREATS: 'TREATS',
  PACKAGING: 'PACKAGING',
  MISC: 'MISC'
};

exports.Allergen = exports.$Enums.Allergen = {
  CHICKEN: 'CHICKEN',
  TURKEY: 'TURKEY',
  BEEF: 'BEEF',
  PORK: 'PORK',
  LAMB: 'LAMB',
  WHITEFISH: 'WHITEFISH',
  DUCK: 'DUCK',
  SALMON: 'SALMON',
  TUNA: 'TUNA'
};

exports.SkuSize = exports.$Enums.SkuSize = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F'
};

exports.DiscountCodeType = exports.$Enums.DiscountCodeType = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  ABSOLUTE_TOTAL: 'ABSOLUTE_TOTAL'
};

exports.DiscountSpecialCondition = exports.$Enums.DiscountSpecialCondition = {
  MULTIPLE_ACTIVE_FRESH_SUBSCRIPTIONS: 'MULTIPLE_ACTIVE_FRESH_SUBSCRIPTIONS'
};

exports.DiscountApplyerType = exports.$Enums.DiscountApplyerType = {
  CUSTOMER: 'CUSTOMER',
  CX: 'CX',
  SYSTEM: 'SYSTEM'
};

exports.DiscountApplicableProduct = exports.$Enums.DiscountApplicableProduct = {
  FRESH_MEAL_PLAN: 'FRESH_MEAL_PLAN',
  HEALTH_SCOOP: 'HEALTH_SCOOP',
  PLANET_SCOOP: 'PLANET_SCOOP',
  CHICKEN_NIBBLES: 'CHICKEN_NIBBLES',
  SALMON_NIBBLES: 'SALMON_NIBBLES',
  CHICKEN_SPRINKLES: 'CHICKEN_SPRINKLES',
  DELIVERY_FEE: 'DELIVERY_FEE'
};

exports.DiscountApplicableChargeType = exports.$Enums.DiscountApplicableChargeType = {
  CHECKOUT: 'CHECKOUT',
  CROSS_SELL: 'CROSS_SELL',
  RECURRING: 'RECURRING'
};

exports.DeliveryDateOrderType = exports.$Enums.DeliveryDateOrderType = {
  RECURRING: 'RECURRING',
  STARTER: 'STARTER',
  ALL: 'ALL'
};

exports.DeliveryDateProductType = exports.$Enums.DeliveryDateProductType = {
  FRESH: 'FRESH',
  HEALTH_SCOOP: 'HEALTH_SCOOP',
  PLANET_SCOOP: 'PLANET_SCOOP',
  ALL: 'ALL'
};

exports.ReasonDeliveryDateDisallowed = exports.$Enums.ReasonDeliveryDateDisallowed = {
  BANK_HOLIDAY: 'BANK_HOLIDAY',
  CUT_OFF: 'CUT_OFF',
  DELIVERY_COMPANY_CAPACITY: 'DELIVERY_COMPANY_CAPACITY',
  SPREADING_DELIVERIES: 'SPREADING_DELIVERIES',
  OTHER: 'OTHER'
};

exports.CheckoutStatus = exports.$Enums.CheckoutStatus = {
  DRAFT: 'DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

exports.CustomerEventLogType = exports.$Enums.CustomerEventLogType = {
  CREATED_SHIPPING_ADDRESS: 'CREATED_SHIPPING_ADDRESS',
  UPDATED_SHIPPING_ADDRESS: 'UPDATED_SHIPPING_ADDRESS'
};

exports.CustomerEventLogSource = exports.$Enums.CustomerEventLogSource = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
  MIGRATION: 'MIGRATION'
};

exports.Prisma.ModelName = {
  Role: 'Role',
  User: 'User',
  UserToRole: 'UserToRole',
  UserPassword: 'UserPassword',
  PasswordResetLink: 'PasswordResetLink',
  AuthLoginCode: 'AuthLoginCode',
  RefreshToken: 'RefreshToken',
  KatkinImage: 'KatkinImage',
  UserImage: 'UserImage',
  CatImage: 'CatImage',
  Cat: 'Cat',
  OrderFulfilment: 'OrderFulfilment',
  OrderFulfilmentItem: 'OrderFulfilmentItem',
  OrderFulfilmentEditLog: 'OrderFulfilmentEditLog',
  BoxSku: 'BoxSku',
  NonOaklandFulfilledCustomer: 'NonOaklandFulfilledCustomer',
  Product: 'Product',
  Sku: 'Sku',
  BoxRule: 'BoxRule',
  BoxRuleItem: 'BoxRuleItem',
  Lock: 'Lock',
  GdprDeletion: 'GdprDeletion',
  GlobalStatistics: 'GlobalStatistics',
  DiscountCategory: 'DiscountCategory',
  DiscountSubcategory: 'DiscountSubcategory',
  DiscountGroup: 'DiscountGroup',
  DiscountCode: 'DiscountCode',
  DiscountAuditLog: 'DiscountAuditLog',
  DiscountApplication: 'DiscountApplication',
  DiscountApplicationToDiscountCharge: 'DiscountApplicationToDiscountCharge',
  DiscountCharge: 'DiscountCharge',
  DiscountedLineItemCharge: 'DiscountedLineItemCharge',
  DiscountedLineItemApplication: 'DiscountedLineItemApplication',
  UserStatistics: 'UserStatistics',
  Charge: 'Charge',
  CreditCharge: 'CreditCharge',
  CreditApplication: 'CreditApplication',
  DisallowedDeliveryDate: 'DisallowedDeliveryDate',
  Breeder: 'Breeder',
  CheckoutBasket: 'CheckoutBasket',
  RechargeOrder: 'RechargeOrder',
  RechargeLineItem: 'RechargeLineItem',
  SurveyResponse: 'SurveyResponse',
  Experiment: 'Experiment',
  ExperimentToUser: 'ExperimentToUser',
  ShippingAddress: 'ShippingAddress',
  CustomerEventLog: 'CustomerEventLog'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        const runtime = detectRuntime()
        const edgeRuntimeName = {
          'workerd': 'Cloudflare Workers',
          'deno': 'Deno and Deno Deploy',
          'netlify': 'Netlify Edge Functions',
          'edge-light': 'Vercel Edge Functions',
        }[runtime]

        let message = 'PrismaClient is unable to run in '
        if (edgeRuntimeName !== undefined) {
          message += edgeRuntimeName + '. As an alternative, try Accelerate: https://pris.ly/d/accelerate.'
        } else {
          message += 'this browser environment, or has been bundled for the browser (running in `' + runtime + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://github.com/prisma/prisma/issues`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
